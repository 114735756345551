.Gold{
    border-radius: 10px;
    background-color: #FFD700;
    height: 75px;
    width: 75px;
    color: black;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
}

.Green-Win{
    border-radius: 10px;
    background-color: #2DD83E;
    height: 75px;
    width: 75px;
    color: black;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
}

.Red-Loss{
    border-radius: 10px;
    background-color: #FA0F1B;
    height: 75px;
    width: 75px;
    color: white;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
}