.mirror{
    //font-family: 'Poppins', sans-serif;
    font-family: 'Roboto Slab', serif;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 3%;
    margin-bottom: 5%;
}

//main heding
.mirror-h-one{
    font-size: 46px;
    text-align: center;
}

//paragraph
.mirror-p{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    //font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    text-align: left;
    margin-top: 10px;
}

//project info box ************************************************************************************************************************
.mirror-info{
    font-family: 'Open Sans', sans-serif;
    background-color: #202324;
    color: white;
    border-radius: 10px;
    display: flex;
    margin: 6% 0 6% 0;
    //max-width: 90%;
    //flex-direction: row;
    //flex-wrap: wrap;
    //width: 100%;

    .mirror-column{
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        margin: 0 3% 2% 8%;
    }
    
    .mirror-column h6{
        font-size: 14px;
        margin: 6% 0 0 0;
        color: #FFD700;
    }

    .mirror-column ul{
        list-style: none;
        font-size: 14px;
        margin: 2px 0;
        padding: 0;
    }

    .mirror-column a{
        color: white;
        text-decoration-color: #FFD700;
        text-decoration-thickness: 1px;
    }

    .mirror-column a:hover{
        text-decoration-thickness: 2px;
        font-weight: bold;
    }
}//project info box ******************************************************************************************************************

//main image
.mirror-img{
    box-shadow: 0px 0px 8px 0px #000000;
    width: 50%;
    height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

//image slider contents--------------------------------------------------------------------------------------------------------------------
.mirror-img2{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 8px 0px #000000;
    border-radius: 5px;
} 

.mirror-slider{
    margin: 5% 0 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 850px;
    //border: 2px solid black;
}

.mirror-slider:after {//allows image slide to be dynamic
    content: "";
    display: block;
    padding-bottom: 68%;
  }

.mirror-arrow-left{
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 5px;
    margin-right: 5px;
}

.mirror-arrow-right{
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 5px;
    margin-right: 5px;
}

.mirror-slider:hover{
    .mirror-arrow-left{
        cursor: pointer;
        transform: translate(-20px);
    }
    .mirror-arrow-right{
        cursor: pointer;
        transform: translate(20px);
    }
}

.mirror-slider-fadeOut{
    opacity: 0;
    transition-duration: 3s ease;
}

.mirror-slider-fadeIn{
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}
@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
} 
//---------------------------------------------End image slider contents------------------------------------------------------------------------------


//CSS conditions when screen size gets smaller
@media screen and (max-width: 960px){
    .mirror{
        font-family: 'Poppins', sans-serif;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5%;
        margin-bottom: 10%;
    }
    
    .mirror-h-one{
        font-size: 32px;
        text-align: center;
    }

    .mirror-p{
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        text-align: left;
        margin-top: 10px;
    }
}