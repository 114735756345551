.TicTac-Status{
    margin-top: 3%;
    height: 100px;
    font-family: "Acme", sans-serif;
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: Black;
}

.TicTac-Board{
    margin-top: 4%;
    //background-color: gray;
    //height: 78vh;
    display: flex;
    justify-content: center;
    align-content: center;
}

.TicTac-Grid-Container{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px 10px;
}

.TicTac-Button{
    padding-top: 5%;
    padding-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}