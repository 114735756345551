.Projects{
    background-color:  #f5f8ff;
    //background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.Projects-Body{
    margin: 0;
    margin-top: 0.5%;
}

.Projects-Footer{
    z-index: 1;
    margin-top: auto;
}