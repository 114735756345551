.Navbar{
    background: black;
    color: white;
    height: 7vh;//height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
}

.nav-myName{
    font-weight: bold;
    padding: 0.5rem, 1rem;
    font-family: 'Poppins', sans-serif;
    .nav-myName-link{
        text-decoration: none;
        color: white;
    }
}

//--------------------------------------------------------------------My Name Animation----------------------------------------------------------------------------

.nav-myName:hover{
    .nav-myName-link{color: #FFD700;}
    animation: animationFrames linear 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFrames linear 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFrames linear 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFrames linear 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFrames linear 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
  }
  
  @keyframes animationFrames{
    0% {transform: translate(0px,0px);}
    10% {transform: translate(-10px,0px);}
    20% {transform: translate(10px,0px);}
    30% {transform: translate(-10px,0px);}
    40% {transform: translate(10px,0px);}
    50% {transform: translate(-10px,0px);}
    60% {transform: translate(10px,0px);}
    70% {transform: translate(-10px,0px);}
    80% {transform: translate(10px,0px);}
    90% {transform: translate(-10px,0px);}
    100% {transform: translate(0px,0px);}
  }
  
  @-moz-keyframes animationFrames{
    0% {-moz-transform: translate(0px,0px);}
    10% {-moz-transform: translate(-10px,0px);}
    20% {-moz-transform: translate(10px,0px);}
    30% {-moz-transform: translate(-10px,0px);}
    40% {-moz-transform: translate(10px,0px);}
    50% {-moz-transform: translate(-10px,0px);}
    60% {-moz-transform: translate(10px,0px);}
    70% {-moz-transform: translate(-10px,0px);}
    80% {-moz-transform: translate(10px,0px);}
    90% {-moz-transform: translate(-10px,0px);}
    100% {-moz-transform: translate(0px,0px);}
  }
  
  @-webkit-keyframes animationFrames {
    0% {-webkit-transform: translate(0px,0px);}
    10% {-webkit-transform: translate(-10px,0px);}
    20% {-webkit-transform: translate(10px,0px);}
    30% {-webkit-transform: translate(-10px,0px);}
    40% {-webkit-transform: translate(10px,0px);}
    50% {-webkit-transform: translate(-10px,0px);}
    60% {-webkit-transform: translate(10px,0px);}
    70% {-webkit-transform: translate(-10px,0px);}
    80% {-webkit-transform: translate(10px,0px);}
    90% {-webkit-transform: translate(-10px,0px);}
    100% {-webkit-transform: translate(0px,0px);}
  }
  
  @-o-keyframes animationFrames {
    0% {-o-transform: translate(0px,0px);}
    10% {-o-transform: translate(-10px,0px);}
    20% {-o-transform: translate(10px,0px);}
    30% {-o-transform: translate(-10px,0px);}
    40% {-o-transform: translate(10px,0px);}
    50% {-o-transform: translate(-10px,0px);}
    60% {-o-transform: translate(10px,0px);}
    70% {-o-transform: translate(-10px,0px);}
    80% {-o-transform: translate(10px,0px);}
    90% {-o-transform: translate(-10px,0px);}
    100% {-o-transform: translate(0px,0px);}
  }
  
  @-ms-keyframes animationFrames {
    0% {-ms-transform: translate(0px,0px);}
    10% {-ms-transform: translate(-10px,0px);}
    20% {-ms-transform: translate(10px,0px);}
    30% {-ms-transform: translate(-10px,0px);}
    40% {-ms-transform: translate(10px,0px);}
    50% {-ms-transform: translate(-10px,0px);}
    60% {-ms-transform: translate(10px,0px);}
    70% {-ms-transform: translate(-10px,0px);}
    80% {-ms-transform: translate(10px,0px);}
    90% {-ms-transform: translate(-10px,0px);}
    100% {-ms-transform: translate(0px,0px);}
  }

//-----------------------------------------------------------End My Name Animation-------------------------------------------------------------------------

.menu-icon{
    color: white;
    display: none;
}

.navbar-menu-one{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 0;//2rem
    font-family: 'Poppins', sans-serif;
}

.nav-links{
    color: white;
    font-weight: bold;
    text-decoration: none;
    padding: 0.5rem, 1rem;
}

.nav-links:hover{
    color: #FFD700;
    transition: all 0.2s ease-out;
}

//sets the navbar when "X" was not presed on mobile
@media screen and (min-width: 960px){
    .Nnavbar-menu-two{
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-gap: 30px;
        list-style: none;
        text-align: center;
        width: 70vw;
        justify-content: end;
        margin-right: 2rem;
        font-family: 'Poppins', sans-serif;
    }    
}

@media screen and (max-width: 960px){
    .Navbar{
        position: relative;
    }

    .navbar-menu-two{
        background: black;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 335px;
        position: fixed;
        padding-left: 0;
        top: 30px;
        bottom: -100%;
        opacity: 1;
        transition: all 1s ease;
        font-family: 'Poppins', sans-serif;
        z-index: 3;
    }

    .navbar-menu-one{
        background: black;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        position: fixed;
        top: 30px;
        bottom: -100%;
        opacity: 0;
        transition: all 1s ease;

        .nav-links{
            padding: 0.1rem;
            width: 100%;
            cursor: none;
        }
        
        visibility: hidden;
    }

    .nav-links{
        text-align: center;
        padding: 1.2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        color: #FFD700;
        background-color: black;
        border-radius: 0;
        transition: all 0.2s ease-out;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 15%);
        //font-size: 1.8rem;
        cursor: pointer;
    }

    .menu-icon:hover{
        color: #FFD700;
    }

    .nav-myName{
        display: block;
        position: absolute;
        left: 50px;
        font-family: 'Poppins', sans-serif;
    }
}
//Gold = #FFD700, #FDEC00

/*
.navbar-menu-notActive{
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    width: 70vw;
}
*/