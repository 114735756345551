.Games{
    background-color:  #f5f8ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.Games-Body{
    margin: 0;
}

.Games-Body-One{
    margin: auto;
}

.Games-Footer{
    //position: absolute;
    //width: 100%;
    //left: 0;
    //bottom: 0;
    //right: 0;
    z-index: 1;
    margin-top: auto;
}